






























































































import { defineComponent, ref, computed, watch } from '@vue/composition-api';
import { GIFT_CONDITION_TYPE, giftConditionTypeOptions, useGiftConditions } from '@/admin/gift';
import { useNotification } from '@/composition/notification';
import { useConfirm } from '@/composition/confirm';
import { getOptionsTitle } from '@/admin/util';
import myAttributes from '@/composition/myAttributes';
import FcRoleLoading from '@/components/FcRoleLoading.vue';
import FcRoleDeny from '@/components/FcRoleDeny.vue';
import { DisplayDate } from '@/admin/util';

export default defineComponent({
  name: 'GiftConditions',
  components: {
    FcRoleLoading,
    FcRoleDeny,
  },
  setup(_, context) {
    const myRoleSettings = computed(() => myAttributes.getRoleSettings('gifts'));

    const { giftConditions } = useGiftConditions();
    const notification = useNotification();
    const { confirmDialog } = useConfirm();
    const headers = [
      { text: '作成日', value: 'createDate.date' },
      { text: '特典条件タイプ', value: 'giftConditionType' },
      { text: '条件', value: 'giftConditionTitle' },
      { text: '付与する特典', value: 'gifts' },
      { text: '', value: 'actions', sortable: false, align: 'end' },
    ];

    // フリーワード検索
    const searchWord = ref('');
    const queryGiftId = ref(context.root.$route.query.giftId);
    const displayGiftConditions = computed(() => {
      const filterGiftCondition = giftConditions.items
        // フリーワードによる絞り込み
        .filter((item) => !searchWord.value || JSON.stringify(item).includes(searchWord.value))
        // クエリーによるアイテムの絞り込み
        .filter((item) => !queryGiftId.value || JSON.stringify(item).includes(String(queryGiftId.value)));
      return filterGiftCondition;
    });

    const deleteGiftCondition = async (giftConditionId: string, giftConditionType: string) => {
      if (!(await confirmDialog('本当に削除しますか？'))) return;
      try {
        await giftConditions.deleteGiftCondition(giftConditionId, giftConditionType);
        giftConditions.items = giftConditions.items.filter((item) => item.giftConditionId !== giftConditionId);
        notification.notify('削除しました。');
      } catch (error) {
        notification.error(error);
      }
    };

    watch(
      () => context.root.$route.fullPath,
      () => {
        queryGiftId.value = context.root.$route.query.giftId;
      }
    );

    return {
      DisplayDate,
      pageTitle: '特典条件',
      myRoleSettings,
      giftConditions,
      GIFT_CONDITION_TYPE,
      giftConditionTypeOptions,
      getOptionsTitle,
      headers,
      deleteGiftCondition,
      searchWord,
      displayGiftConditions,
      queryGiftId,
    };
  },
});
